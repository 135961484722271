@import 'global_elements/variables/shared_dimensions.scss';
@import 'global_elements/variables/colors.scss';

.patient-registration-content {
    form {
        width: $MAIN-CONTENT-WIDTH;

        label > .text {
            margin: 0 0 0.5rem 0;
        }
    }

    .button-container {
        align-self: flex-end;

        & > button {
            width: 160px;
            height: 48px;
            padding: 0;

            &:first-child {
                margin: 0 2rem 0 0;
            }
        }
    }

    &__first-row, &__second-row, &__third-row, &__fourth-row, &__fifth-row {
        width: 100%;
        margin: 0 0 1.5rem 0;

        & > label {
            display: flex;
            flex-direction: column;
            width: 26%;

            &:not(:last-child) {
                padding: 0 2rem 0 0;
            }
        }
    }

    &__first-row {
        &__facility-header {
            .btn {
                padding: 3px 0 0 1rem;

                .text {
                    &:hover {
                        font-weight: 600;
                        color: $brand-primary;
                    }
                }
            }
        }
    }

    &__second-row {
        & > label {
            &:nth-child(2) {
                width: 40%;
            }
        }
    }

    @media (max-width: $MOBILE-BREAKPOINT) {
        form {
            width: 100%;
        }

        &__first-row, &__second-row, &__third-row, &__fourth-row, &__fifth-row {

            & > label {
                width: 40%;
            }
        }
    }
}
