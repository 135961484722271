@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.printable-assessments-page-content {

  .printable-assessments-widget {
    width: 100%;

    &__header {
        width: 100%;
        margin: 0 0 1rem 0;
    }

    &__content {
      width: 100%;
      border: 1px solid $brand-dark;

      & > * {
        width: calc(33.33% - 1rem);

        @media (min-width: 1025px) {
          &:nth-child(3n) {
            padding: 0 1rem 0 0.5rem;
          }

          &:nth-child(3n-2) {
            padding: 0 0.5rem 0 1rem;
          }
        }

        .external-link {
          display: flex;
          align-items: center;
          width: calc(100% - 0.5rem);
          text-decoration: none;

          & > svg {
            min-width: 25px;
            min-height: 15px;
          }

          & > span.text {
            margin: 0.5rem 0 0.5rem 0;
          }

          & > svg:hover ~ span.text, span.text:hover {
            text-decoration: underline;
            font-weight: 600;
          }
        }

        .collapsible-section__content {
            border-bottom: 1px solid $brand-negative-space;
            border-right: 1px solid $brand-negative-space;
        }
      }
    }
  }
}

.pdf-frame {
  overflow: hidden;
  height: calc(100vh - 32px);
  width:100%;
  border: none;
}

@media (max-width: $MOBILE-BREAKPOINT) {

  .printable-assessments-page-content {

    .printable-assessments-widget {

      &__header {
        .search-bar > input {
          width: 210px;
        }
      }

      &__content > * {
        width: calc(50% - 2rem);
        padding: 0 1rem;
      }
    }
  }
}
