@import '../variables/colors.scss';

.btn {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    pointer-events: auto;
    padding: 1rem;
    transition: 0.2s all linear;
    transition: 0s outline linear;

    &:focus {
        outline: $brand-primary solid 5px;
    }

    &.--invisible:focus {
        outline: $brand-low-priority solid 5px;
    }

    &.--primary {
        background-color: $brand-secondary;

        &:hover {
            background-color: $brand-primary;
        }
    }

    &.--secondary {
        background-color: $brand-light-gray;

        &:hover {
            background-color: $brand-dark;
        }
    }

    &.--dark {
        background-color: $brand-primary;

        &:hover {
            background-color: $brand-secondary;
        }
    }

    &.--high-priority {
        background-color: $brand-high-priority;

        &:hover {
            background-color: $brand-high-priority-dark;
        }
    }

    &.--primary, &.--secondary, &.--dark, &.--high-priority {
        &:disabled {
            background-color: $brand-negative-space;
            pointer-events: none;

            &:hover {
                background-color: $brand-negative-space;
            }
        }
    }
}
