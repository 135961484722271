@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.core-measures-worklist-content {
    form {
        width: $MAIN-CONTENT-WIDTH;

        & > label > * {
            width: 50%;
        }


        & > label > *:last-child, & > p {
            margin: 0 0 2rem 0;
        }
    }

    .core-measures-worklist-table {

        .rdt_TableRow,
        .rdt_TableHeadRow {
            & > div:nth-child(1) {
                min-width: 8.5em;
                max-width: 8.5em;
            }
            & > div:nth-child(2) {
                min-width: 12.5em;
            }
            & > div:nth-child(3) {
                min-width: 12em;
            }
            & > div:nth-child(4) {
                min-width: 12em;
            }
            & > div:nth-child(5) {
                min-width: 10em;
                max-width: 12em;
            }
            & > div:nth-child(6) {
                min-width: 10em;
                max-width: 12em;
            }
            & > div:nth-child(7) {
                min-width: 7em;
                max-width: 7em;
            }
            & > div:nth-child(8) {
                min-width: 9em;
                max-width: 9em;
            }
            & > div:nth-child(9) {
                min-width: 8em;
                max-width: 8em;
            }
        }
        
    }

    .patient-completion-table {

        .rdt_TableRow,
        .rdt_TableHeadRow {
            & > div:nth-child(1) {
                min-width: 12em !important;
            }
            & > div:nth-child(2) {
                min-width: 9em !important;
                max-width: 9em !important;
            }
            & > div:nth-child(3) {
                min-width: 9em !important;
                max-width: 9em !important;
            }
            & > div:nth-child(4) {
                min-width: 10.5em !important;
                max-width: 10.5em !important;
            }
            & > div:nth-child(5) {
                min-width: 10.5em !important;
                max-width: 10.5em !important;
                
            }
            & > div:nth-child(6) {
                min-width: 10.5em !important;
                max-width: 10.5em !important;
            }
        }
    }

    .incomplete-patients-table {

        .rdt_TableRow,
        .rdt_TableHeadRow {
            & > div:nth-child(1) {
                min-width: 15em;
            }
            & > div:nth-child(2) {
                min-width: 7em;
            }
            & > div:nth-child(3) {
                min-width: 10em;
            }
            & > div:nth-child(4) {
                min-width: 10em;
            }
            & > div:nth-child(5) {
                min-width: 10em;
            }
            & > div:nth-child(6) {
                min-width: 11em;
            }
        }
    }

    .button-container {
        &__search, &__add-account {
            align-self: flex-end;

            & > button {
                width: 160px;
                height: 48px;
                padding: 0;

                &:not(:last-child) {
                    margin: 0 2rem 0 0;
                }
            }
        }

        &__search {
            margin: 0 0 1rem 0;
        }

        &__add-account {
            margin: 4rem 0;
        }
    }

    &__text-inputs {
        width: 100%;
        margin: 0 0 1.5rem 0;

        & > label {
            width: calc(25% - 0.75rem);

            &:not(:last-child) {
                padding: 0 1rem 0 0;
            }
        }
    }

    &__list-inputs {
        width: 100%;
        margin: 0 0 1.5rem 0;

        & > label {
            width: 25%;

            &:not(:last-child) {
                padding: 0 1rem 0 0;
            }
        }
    }

    &_date-range-inputs {
        width: 100%;
        margin: 0 0 1.5rem 0;

        & > label {
            width: calc(25% - 0.75rem);

            &:not(:last-child) {
                padding: 0 1rem 0 0;
            }
        }
    }

    .completion-grid-button {
        color: red;
        text-decoration: underline blue;
    }

    .popup-window {
            width:  auto;
            height: 50vh;
            overflow-y: auto;
    }

    @media (max-width: $MOBILE-BREAKPOINT) {
        form, .core-measures-worklist-table, .rdt_Table {
            width: 100%;
        }
    }

    .print {
        display: none;
    }

    @media print {    
        .no-print, .no-print *
        {
            display: none !important;
        }

        .print {
            display: contents;
        }
    }
}