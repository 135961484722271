.collapsible-section {
    width: 100%;

    .btn {
        padding: 1rem 1rem 1rem 0;
    }

    hr {
        width: 100%;
        margin: 0;
    }

    &__banner {
        &__container {
            .text {
                margin: 0 1rem 0 0;
            }

            &.arrow--left {
                svg {
                    margin-right: 20px;
                }
            }
        }
    }

    &__content {
        width: 100%;

        &.arrow--left {
            margin-left: 36px;
            width: auto;
        }
    }
    @media print {
        .no-print {
            display: none !important;
        }
    }
}
