@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

$CARD-WIDTH: calc(#{$MAIN-CONTENT-WIDTH} - 70%);
$CARD-TYPE-WIDTH: 80px;

.provider-assessment-card-wrapper {
    width: $CARD-WIDTH;
    height: 130px;
    border: 1px solid $brand-gray;
    background-color: white;
    transition: border 0.15s linear;
    transition: background-color 0.15s linear;
    position: relative;

    &:not(:nth-child(3n)) {
        margin: 0 1rem 1rem 0;
    }

    &:last-child {
            margin: 0 0rem 1rem 0;
    }

    &:hover {
        border: 1px solid $brand-secondary;
    }

    .provider-assessment-card-content {
        padding-top: 10px;
        width: 95%;
        height: 100%;
        padding-bottom: 20%;
        justify-content: start;

        @media (max-width: 923px) {
          p {
            font-size: 0.75rem;
          }

          .completed-when p {
            // font-size: 0.5rem;
          }
        }

        .provider-assessment-card-content-header {
          width: 95%;
        }

        .provider-assessment-card-content-header-text {
          width: 95%;
        }
    }

    .provider-assessment-card-bottom {
        width: 95%;
        position: absolute;
        bottom: 0;
        align-items: center;
        justify-content: end;

        .completed-when {
            width: 50%;

            p {
              background: white;
            }
        }

        .provider-assessment-card-cta {
            margin: 0 1rem 0 0;
            align-items: center;
            width: 100%;

            .btn {
                padding: 0.75rem 1rem;
            }

            & > *:first-child:not(:only-child) {
                margin: 0 1rem 0 0;

                @media (max-width: 923px) {
                  margin: 0 0.5rem 0 0.5rem;

                  p {
                    font-size: 0.55rem !important;
                  }
                }
            }

            .text:hover {
                color: $brand-primary;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }

    .--limit-width {
      max-width: calc(100% - $CARD-TYPE-WIDTH - 5px);
    }

    .provider-assessment-card-type {
        position: absolute;
        width: $CARD-TYPE-WIDTH;
        top: 0px;
        right: 0px;
        padding: 5px 0;
        border-bottom-left-radius: 2px;
        background-color: #37b597;

        &.--admission {
            background-color: $brand-secondary;
        }

        &.--during {
            background-color: green;
        }

        &.--discharge {
            background-color: $brand-primary;
        }
    }

    @media (max-width: $MOBILE-BREAKPOINT) {
        width: calc(33% - 1rem);
    }
}

.popup-window-wrapper {
    display: flex;
    position: fixed;
    top: $HEADER-HEIGHT;
    left: $NAV-BAR-WIDTH;
    height: calc(100% - $HEADER-HEIGHT);
    width: calc(100% - $NAV-BAR-WIDTH);
    background: rgba(0, 0, 0, 0.25);
    z-index: 2;

    .popup-window {
        width: 600px;
    }

    .popup-window-content {
        width: 100%;

        .popup-window-header {
            width: 100%;
            margin-bottom: 32px;

            button {
                margin-right: -16px;
            }
        }

        .previously-sent-text {
            margin-bottom: 32px;
        }

        form {
            width: 100%;
            
            button {
                display: block;
            }
        }

        .input-text {
            margin-bottom: 32px;
            max-width: 574px;
        }

    }

    @media (max-width: $MOBILE-BREAKPOINT) {
        left: 0;
        width: 100%;

        .popup-window {
            width: 80%;
        }
    }
}

.frozen {
    background-color: $brand-negative-space !important;
}