@import 'global_elements/variables/colors.scss';

.sv_save_msg {
  text-align: center;
  padding: 1rem;

  .sv_save_msg__error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    input {
      margin-top: 0.5rem;
    }

    span {
      color: $brand-high-priority;
      font-weight: 700;
    }
  }

  span {
    font-size: 1rem;
  }
}

.sv-action-bar.sv-action-bar--default-size-mode.sv_nav {
  display: flex;
  flex-direction: row-reverse;
}

.metabolic-select-wrapper {
  width: 175px !important;
}

.sv_q--disabled {
  .sv_q_text_root {
 background-color: $brand-negative-space !important;
  }
 

}