@import 'global_elements/variables/colors.scss';

.facility-dashboard-content {
    .text {
        margin: auto 0 auto 0;
    }

    .control-bar-top {
      margin: 10px;
      label {
        padding-right: 20px;
      }
    }

    .work-list-section {
        width: 100%;

        &__buttons {
            & > *:first-child {
                margin: 0 2rem 0 0;
                text-decoration: none;
            }
        }
    }

    .work-list-section {

        .multi-select-dropdown-wrapper > div {
            max-height: 41px;
        }

        .search-bar {
            max-height: 40px;
            margin: 0 2rem 0 0;

            input {
                height: 14px;
            }
            > div {
                height: 40px;
            }
        }
    }

    .provider-work-list {
        width: 100%;
        margin: 1rem 0 4rem 0;

        .rdt_TableRow,
        .rdt_TableHeadRow {
            & > div:nth-child(1) {
                min-width: 200px;
            }
            & > div:nth-child(2) {
                min-width: 150px;
            }
            & > div:nth-child(3) {
                min-width: 150px;
            }
            & > div:nth-child(5) {
                min-width: 220px;
            }
        }

        .rdt_TableCol > div > div {
            white-space: normal;
        }
    }
}
