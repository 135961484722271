@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

nav.mho-nav-bar {
    z-index: 10;
    position: fixed;
    top: $HEADER-HEIGHT;
    min-height: $PAGE-HEIGHT-MIN;
    width: $NAV-BAR-WIDTH;
    padding: 0;
    margin: 0;
}

.logged-out-page {
    background-color: $brand-negative-space;
    height: 100vh;
    width: 100vw;

    &__header {
        height: 200px;
        background-color: $brand-primary;
        color: white;
        width: 100%;

        &__text {
            margin: 12px;

            & > .text:first-child {
                margin: 0 1rem 0 0;
            }
        }

        svg {
            margin: 0.75rem 0;
        }
    }

    main {
        height: calc(100vh - 200px);
    }
}

.main-wrapper {
    position: relative;
    left: $NAV-BAR-WIDTH;
    width: $MAIN-WRAPPER-WIDTH;
    background-color: $brand-negative-space;
    margin: $HEADER-HEIGHT 0 0 0;

    @media (max-width: $MOBILE-BREAKPOINT) {
        width: 100%;
        left: unset
    }

    @media print {
        background-color: transparent;
        left: 0;
        margin-top: 0;
        width: 100%;
        margin-bottom: 0;
    }

    &.--padded {
        main {
            width: $MAIN-WIDTH;
            min-height: $PAGE-HEIGHT-MIN;
            height: auto;
            margin: 0 $MAIN-SIDE-MARGIN $MAIN-BOTTOM-MARGIN $MAIN-SIDE-MARGIN;

            @media print {
              margin-bottom: 0;
            }

            & > div {
                padding: $MAIN-PADDING;
                background-color: $brand-background;

                @media print {
                    padding: 0.5rem;
                }

                &:first-child {
                    height: auto;
                    margin: 0 0 $MAIN-SIDE-MARGIN 0;

                    @media print {
                        margin: 0;
                    }
                }

                &:last-child {
                    height: auto;
                }
            }
        }

        article {
            margin: $NOTICE-VERTICAL-MARGIN $MAIN-SIDE-MARGIN;

            & >.btn {
                display: flex;
                align-items: center;
                padding: 0;

                & > svg {
                    width: 8px;
                    height: 8px;
                    margin: 0 0.1rem 0 0;
                }

                & > .text {
                    &:hover {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    &.--full-screen {
        main {
            width: 100%;
        }

        article {
            display: none;
        }
    }
}
