@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.mho-nav-bar {
    &.--primary {
        background-color: $brand-primary;
    }

    .super-admin-references {
        margin: 1rem 0 0 0;
        width: $NAV-MAIN-ITEM-WIDTH;

        & > .text {
            padding: 0 0 0 $NAV-MAIN-TEXT-PADDING-LEFT;
        }
    }

    @media (max-width: $MOBILE-BREAKPOINT) {
        &#mho-nav-bar {
            display: none;

            &.shown {
                display: block
            }
        }
    }

    @media print {
        display: none;
    }
}
