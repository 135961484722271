.group-therapy-add-assessments-container {
  .group-therapy-form {
    .labeled-single-select,
    .labeled-multi-select {
      display: block;
      margin-bottom: 32px;
      width: 400px;
    }

    button {
      margin-top: 32px;
    }
  }
}