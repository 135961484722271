@import 'global_elements/variables/shared_dimensions.scss';

.patient-information-card {
    width: $MAIN-CONTENT-WIDTH;
    margin: 0 0 2.5rem 0;

    &__header {
        width: 100%;

        & > button {
            padding: 0.5rem 2.5rem;
        }

        @media print {
          display: none;
        }
    }

    &__columns {
        width: 100%;

        @media (max-width: 900px) {
          div.flex-col > span {
            font-size: 0.65rem;
          }
        }

        & > *:not(:first-of-type) {
            margin: 0 2rem 0 0;
        }

        @media print {
          display: none;
        }
    }

    &__print-label {
        display: none;
        width: 100%;
        height: 100%;

        h2 {
          width: auto;
        }

        @media print {
          display: flex;
        }
    }

    @media print {
        border: 1px solid silver;
        border-radius: 10px;
        width: 300px;
        height: 100px;
        position: absolute;
        top: 0;
        right: 0;

        .btn {
          display: none;
        }
    }

    @media (max-width: $MOBILE-BREAKPOINT) {
      width: unset;
    }
}
