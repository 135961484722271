.account-delete-confirm-page {
  padding: 25px;

  &__button-container {
    margin-top: 1rem;

    button:not(:last-child) {
      margin-right: 10px;
    }
  }
}
