
.faq-container .collapsible-section {
    &__content {
        .text {
          margin-bottom: 1.5rem;
        }
    }

    &:last-child {
        hr {
            display: none;
        }
    }

    .bold {
      font-weight: 550;
    }
}
