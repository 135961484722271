.login-content-container {
  &.login-panel {
    background-color: white;
    padding: 2rem;
    margin-top: 3rem;

    & label {
      width: 30rem;
    }
  }

  & > * {
    margin: 1rem 0;
  }

  & form {
    margin-bottom: 0;

    & button {
      margin: 2rem 0 1rem 0;
    }
  }
}
