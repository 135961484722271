@import 'global_elements/variables/shared_dimensions.scss';
@import 'global_elements/variables/colors.scss';

.patient-account-settings-page {
    // ***margin/space after email
    &__account {
        &__email {
            margin: 0 0 1rem 0;

            &__content {
                width: 100%;
            }
        }

        &__information {
            & > * {
                margin: 0 0 1rem 0;
            }

            &__buttons {
                width: 100%;
                margin: 2rem 0;

                & button {
                    width: 150px;

                    &:first-child {
                        width: 200px;
                    }

                    &:not(:last-child) {
                        margin: 0 2rem 0 0;
                    }
                }

                &__save {
                    width: 100%;
                }
            }

            &__first-row {
                width: 100%;

                & > * {
                    width: 30%;
                }
            }

            &__second-row {
                width: 100%;

                & > * {
                    width: 30%;

                    &:nth-child(3) {
                        width: 10%;
                    }

                    &:nth-child(4) {
                        width: 15%;
                    }
                }
            }

            &__third-row {
                width: 100%;

                & > * {
                    width: 30%;
                }

                &__notifications {
                    &__toggles {
                        width: 100%;

                        @media (max-width: 1180px) {
                          flex-direction: column;
                          align-items: flex-start !important;
                        }

                        span {
                            display: flex;
                            align-items: center;

                            &:first-child {
                                margin: 0 2rem 0 0;
                            }

                            & > *:first-child {
                                margin: 0 0.5rem 0 0;
                            }
                        }

                        .toggle {
                            position: relative;
                            display: inline-block;
                            width: 50px;
                            height: 20px;

                            input {
                                opacity: 0;
                                width: 0;
                                height: 0;

                                &:checked + .slider {
                                    background-color: $brand-secondary;

                                    &::before {
                                        -webkit-transform: translateX(26px);
                                        -ms-transform: translateX(26px);
                                        transform: translateX(26px);
                                    }
                                }

                                &:focus + .slider {
                                    box-shadow: 0 0 5px $brand-primary;
                                    // background: red;
                                }
                            }

                            .slider {
                                position: absolute;
                                cursor: pointer;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: #ccc;
                                -webkit-transition: .4s;
                                transition: .4s;
                                border-radius: 20px;

                                &::before {
                                    position: absolute;
                                    content: "";
                                    height: 12px;
                                    width: 12px;
                                    left: 6px;
                                    bottom: 4px;
                                    background-color: white;
                                    -webkit-transition: .4s;
                                    transition: .4s;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__patient {
        &__emergency-contact {
            margin: 0 0 2rem 0;

            & > * {
                margin: 0 0 1rem 0;
            }

            &__first-row {
                width: 100%;

                & > * {
                    width: 30%;
                }
            }

            &__second-row {
                width: 65%;

                & > * {
                    width: 46%;
                }
            }
        }

        &__preferred-provider {
            margin: 0 0 2rem 0;

            & > * {
                margin: 0 0 1rem 0;
            }

            &__first-row {
                width: 100%;

                & > * {
                    width: 30%;
                }
            }
        }

        &__buttons {
            width: 100%;
            margin: 2rem 0 6rem 0;

            & > * {
                width: 150px;

                &:first-child {
                    margin: 0 2rem 0 0;
                }
            }
        }

        &__treatments {
            width: 100%;

            & > * {
                width: 100%;
            }
        }

        &__add-treatment {
            margin: 2rem 0 0 0;
        }
    }

    .react-tabs {
        -webkit-tap-highlight-color: transparent;

        &__tab-list {
            border-bottom: none;
            margin: 0;
            padding: 0;
            width: $MAIN-CONTENT-WIDTH;
        }

        &__tab {
            background-color: $brand-gray;
            border: 3px solid $brand-gray;
            border-bottom: 5px solid $brand-gray;
            display: inline-block;
            position: relative;
            list-style: none;
            min-width: 200px;
            padding: 0.25rem 0;
            cursor: pointer;
            text-align: center;

            &:hover:not(&--selected) {
                background-color: $brand-negative-space;
                border-color: $brand-negative-space;
            }

            &--selected {
                background-color: white;
                border-top: 3px solid $brand-primary;
                border-left: 3px solid $brand-primary;
                border-right: 3px solid $brand-primary;
                border-bottom: none;

                &:hover {
                    background-color: white;
                }

                &:focus {
                    outline: none;
                }

                &::after {
                    display: block;
                    content: '';
                    position: relative;
                    border-bottom: 5px solid $brand-background;
                    top: 0.6rem;
                    z-index: 1;
                }
            }

            &--disabled:not(.react-tabs__tab--selected) {
                background-color: $brand-negative-space;
                border: 3px solid $brand-negative-space;
                border-bottom: 5px solid $brand-negative-space;
                cursor: default;
            }
        }

        &__tab-panel {
            display: none;
            border: 3px solid $brand-primary;
            padding: 1.5rem;

            &--selected {
                display: block;
            }
        }
    }

    .patient-stays {
      border: 1px solid $brand-gray;
      min-height: 100px;
      padding-top: 10px;
    }

    .patient-stays--container {
      margin-left: 10px;

      span {
        margin-right: 30px;
      }
    }

    .popup-window {
      left: 43%;
    }

    .link-stays--popup {

      input {
        width: 350px;
      }
    }
}
