// Padding and Margin
$MAIN-SIDE-MARGIN: 2.5rem;
$MAIN-BOTTOM-MARGIN: 3rem;
$MAIN-PADDING: 1.875rem;
$NOTICE-VERTICAL-MARGIN: 0.9rem;
$HEADER-RIGHT-SIDE-MARGIN: 2.5rem;
$NAV-MAIN-TEXT-PADDING-TOP: 1.2rem;
$NAV-MAIN-TEXT-PADDING-LEFT: 3.5rem;

// Width and Height
$NAV-BAR-WIDTH: 315px;
$PAGE-HEIGHT-MIN: 100vh;
$HEADER-HEIGHT: 75px;
$MAIN-WIDTH: calc(100% - (#{$MAIN-SIDE-MARGIN} * 2));
$MAIN-WRAPPER-WIDTH: calc(100vw - #{$NAV-BAR-WIDTH});
$MAIN-CONTENT-WIDTH: calc(100vw - #{$NAV-BAR-WIDTH} - ((#{$MAIN-SIDE-MARGIN} + #{$MAIN-PADDING}) * 2));
$HEADER-RIGHT-WIDTH: calc(100vw - #{$NAV-BAR-WIDTH} - (#{$HEADER-RIGHT-SIDE-MARGIN} * 2));
$NAV-MAIN-ITEM-HEIGHT: calc(65px - #{$NAV-MAIN-TEXT-PADDING-TOP});
$NAV-MAIN-ITEM-WIDTH: calc(100% - #{$NAV-MAIN-TEXT-PADDING-LEFT});
$MOBILE-BREAKPOINT: 1340px;
