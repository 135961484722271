@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.patient-assessment-card-wrapper {
    width: 30%;
    height: 100px;
    border: 1px solid $brand-gray;
    background-color: white;
    transition: border 0.15s linear;
    transition: background-color 0.15s linear;

    @media (min-width: 1025px) {
      &:not(:nth-child(3n)) {
          margin: 0 1rem 1rem 0;
      }
    }


    @media (max-width: $MOBILE-BREAKPOINT) {
      width: 45%;

      &:not(:nth-child(2n)) {
          margin: 0 1rem 1rem 0;
      }
    }

    &:hover {
        // border: 1px solid $brand-secondary;
        // background-color: $brand-negative-space;
        // cursor: pointer;

        & > .patient-assessment-card-right {
            // background-color: $brand-primary;
        }
    }

    .btn-fill {
      width: 100%;
      height: 100%;
      padding: 0 2rem;
    }

    .patient-assessment-card-left {
        width: 100%;
        height: 70%;
        padding: 0 1rem;

        @media (max-width: $MOBILE-BREAKPOINT) {
          p {
            font-size: 0.65rem;
          }
        }
    }

    .patient-assessment-card-right {
        height: 100%;
        background-color: $brand-secondary;
        transition: background-color 0.15s linear;

        &:hover {
          background-color: $brand-primary;
        }

        @media (max-width: $MOBILE-BREAKPOINT) {
          p {
            font-size: 0.65rem;
          }
        }
    }
    .patient-assessment-card-right:focus, .patient-assessment-card-right:active {
      outline: none;
      background-color: #34ad08;
    }
    .patient-assessment-card-right button.btn:focus {
      outline: none;
    }
}
