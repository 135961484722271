.daisy-chain-assessments-page {
  height: 100%;

  .sv_progress {
    top: 0 !important;
  }

  main {
    height: 100%;
  }
}
