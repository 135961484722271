@import 'global_elements/variables/colors.scss';

.first-time-account-setup {
    width: 50vw;
    margin: 2rem 0 0 0;
    padding: 2rem;
    background-color: $brand-background;

    &__patient-info {
        width: 100%;

        & > * {
            width: 30%;
        }
    }

    &__account-info {
        width: 100%;

        & > *:first-child {
            margin: 0 0 1rem 0;
        }

        &__first-row, &__second-row {
            width: 100%;

            & > * {
                width: 50%;

                &:first-child {
                    margin: 0 2rem 0 0;
                }
            }
        }
    }

    &__buttons {
        margin: 2rem 0 1rem 0;
        align-self: end;

        button {
            width: 160px;

            &:first-of-type {
                margin: 0 2rem 0 0;
            }
        }
    }
}