@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.facility-library-content {
  form {
    width: $MAIN-CONTENT-WIDTH;

    & > label > * {
      width: 50%;
    }


    & > label > *:last-child, & > p {
      margin: 0 0 2rem 0;
    }
  }

  &__text-inputs {
    width: 100%;
    margin: 0 0 1.5rem 0;

    & > label {
      width: calc(25% - 0.75rem);

      &:not(:last-child) {
        padding: 0 1rem 0 0;
      }
    }
  }

  &__list-inputs {
    width: 100%;
    margin: 0 0 1.5rem 0;

    & > label {
      width: 25%;

      &:not(:last-child) {
        padding: 0 1rem 0 0;
      }
    }
  }

  &_date-range-inputs {
    width: 100%;
    margin: 0 0 1.5rem 0;

    & > label {
      width: calc(25% - 0.75rem);

      &:not(:last-child) {
        padding: 0 1rem 0 0;
      }
    }
  }
}

.facility-library-content {
  .facility-library-container {
    width: 100%;

    a {
      text-decoration: none;
      color: $brand-secondary;

      &:hover {
        text-decoration: underline;
        font-weight: 600;
      }
    }

    .facility-library-folders-container {
      width: 20%;
      height: 100%;
      padding-left: 3px;
      padding-right: 3px;
      margin-right: 10px;
      border: solid 1px #aaa;

      .facility-library-folder {
        width: 100%;

        a {
          width: 100%;
        }
      }

      .facility-library-folder-selected {
        font-weight: 600;

        a {
          text-decoration: underline;
        }
      }

      .facility-library-folder-is-drag-target {
        background-color: #ffeeba;
      }
    }

    .facility-library-contents {
      width: 80%;
      padding-left: 3px;
      padding-right: 3px;

      .facility-library-admin {
        margin-bottom: 20px;
      }

      .facility-library-error {
        margin-bottom: 20px;
      }

      .facility-library-contents-container {
        width: 100%;

        .facility-library-contents-folder, .facility-library-contents-file {
          width: 33%;
          margin-right: 2px;
          margin-top: 2px;
          margin-bottom: 2px;

          .facility-library-contents-folder-name, .facility-library-contents-file-name {
            width: 100%;

            span a {
              white-space: nowrap;

              span {
                white-space: normal;
                overflow-wrap: anywhere;
              }
            }
          }
        }
      }

      .facility-library-dropzone {
        position: relative;
        width: 100%;
        border: 3px dotted #000;
        background-color: #ccc;
        height: 75px;
        margin-top: 20px;

        span {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          overflow: hidden;
          text-align: center;
          padding: 25px;
        }

        input[type=file] {
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }

      .facility-library-dropzone-hover {
        border-style: solid;
        background-color: rgba(25, 135, 84, .8);
      }
    }
  }
}
