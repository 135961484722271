@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.consent-btns--margin-right {
  margin-right: 1.5em;
}


.patient-consent {
  width: 50vw;
  margin: 2rem 0 0 0;
  padding: 2rem;
  background-color: $brand-background;

  @media (max-width: $MOBILE-BREAKPOINT) {
    width: 80vw;
  }

  .inline-flex {
    display: inline-flex;
  }

  .control-bar-top label {
    margin-left: 1rem;
  }

  &__terms {
    width: 100%;

    & > * {
      width: 30%;
    }

    p {
      width: 100%;
    }
  }

  &__buttons {
    margin: 2rem 0 1rem 0;
    align-self: end;

    button {
      width: 160px;

      @media (max-width: $MOBILE-BREAKPOINT) {
        width: 120px;
      }

      &:first-of-type {
          margin: 0 2rem 0 0;
      }
    }
  }
}

.signature-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: $brand-negative-space;
  border-width: 10px;
  border-color: black;
  width: 100%;

  div {
    margin: 1rem;
  }

  .bold {
    display: block;
    font-weight: bold;
  }
}
