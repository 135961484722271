@import 'global_elements/variables/shared_dimensions.scss';

.facility-user-account-settings-content {
    width: $MAIN-CONTENT-WIDTH;

    @media (max-width: $MOBILE-BREAKPOINT) {
      width: auto;
    }

    &__permission_tree > .text {
        margin: 0 0 0.5rem 0;
    }

    &__first-row {
        width: 100%;

        & > * {
            width: 30%;
            word-break: break-all;

            &:not(:last-child) {
                margin: 0 2rem 0 0;
            }
        }
    }

    &__permission-tree {
        width: 100%;
        margin: 1rem 0;

        & > * {
            width: 100%;
        }
    }

    .facility-user-account-settings--btn-container {
        width: 100%;
        margin-bottom: 2rem;
    }
}
