@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.patient-dashboard-patient-view {
    .assessment-card-container {
        width: 100%;

        &:last-of-type {
            margin: 0 0 4rem 0;
        }
    }
    
    hr {
        width: 100%;
        margin: 2rem 0 1rem 0;
    }
    
    .react-tabs {
        -webkit-tap-highlight-color: transparent;
    
        &__tab-list {
            border-bottom: 1px solid $brand-gray;
            margin: 0 0 10px 0;
            padding: 0;
            width: $MAIN-CONTENT-WIDTH;
        }
    
        &__tab {
            background-color: white;
            display: inline-block;
            position: relative;
            list-style: none;
            padding: 6px 20px;
            cursor: pointer;
            transition: background-color 0.15s linear;
    
            &:hover {
                background-color: $brand-negative-space;
            }
    
            &--selected {
                background-color: white;
                border-bottom: 5px solid $brand-secondary;
                color: $brand-dark;
    
                &:hover {
                    background-color: white;
                }

                &:focus {
                    outline: none;
                }
            }
    
            &--disabled {
                color: $brand-gray;
                cursor: default;
            }
        }
    
        &__tab-panel {
            display: none;
    
            &--selected {
                display: block;
            }
        }
    }    
}