.print-signature-container {
    width: 100%;
    display: none;
    justify-content: end;
    margin-bottom: 0 !important;
    margin-top: 0;
    break-after: avoid;
    break-inside: avoid;

    @media print {
      display: flex;
    }

    .print-signature {
      display: flex;
      width: 65%;
      align-content: flex-end;
      border-top: 1px solid black;
      justify-content: space-between;
      margin-top: 80px;

      .text {
          font-size: 0.75rem;
      }

      .date {
          width: 20%;
      }

    }
}
