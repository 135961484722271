@import 'global_elements/variables/shared_dimensions.scss';

.register-by-pin-content {
    &__inputs {
        width: 100vw;
        margin: 4rem 0 2rem 0;

        & > * {
            margin: 1rem 0;

            &:first-child {
                margin: 0 0 2rem 0;
            }
        }

        label {
            width: 25%;
        }
    }
    
    &__text {
        .btn, & > .text {
            margin: 0;
            padding: 0;
        }

        & > .text:first-child:not(:only-of-type) {
            margin: 0 0 2rem 0;
        }
    }
}