@import 'global_elements/variables/colors.scss';
// CSS for the extreme messaging popup, when the dialog component couldn't be used.

.modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity .25s ease;
  z-index: 100;
}

.modal__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.modal-state {
  display: none;
}

.modal-state:checked + .modal {
  opacity: 1;
  visibility: visible;
}

.modal-state:checked + .modal .modal__inner {
  top: 0;
}

.modal__inner {
  transition: top .25s ease;
  position: absolute;
  top: 100px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 33%;
  margin: auto;
  overflow: auto;
  background: #fff;
  border-radius: 5px;
  padding: 1em 2em;
  height: fit-content;
  max-height: 50%;
  white-space: initial;

  h3 {
    color: $brand-primary;
    font-size: 1rem;
  }
}


.modal__close {
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.1em;
  height: 1.1em;
  cursor: pointer;
}

.modal__close:after,
.modal__close:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 1.5em;
  background: #ccc;
  display: block;
  transform: rotate(45deg);
  left: 50%;
  margin: -3px 0 0 -1px;
  top: 0;
}

.modal__close:hover:after,
.modal__close:hover:before {
  background: #aaa;
}

.modal__close:before {
  transform: rotate(-45deg);
}

.p-wrap {
  white-space: initial;
}

@media screen and (max-width: 768px) {

  .modal__inner {
    width: 90%;
    height: 90%;
    box-sizing: border-box;
  }
}
