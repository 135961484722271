@import 'global_elements/variables/shared_dimensions.scss';
@import 'global_elements/variables/colors.scss';

.help-page {
    &__documents {
        width: $MAIN-CONTENT-WIDTH;

        p.text {
            margin: 0 0 1rem 0;
        }

        &__links {
            width: 100%;

            & > * {
                width: 30%;
                margin: 0 0 1rem 0;
            }

            .external-link {
                display: flex;
                // align-items: center;
                align-items: start;
                text-decoration: none;

                & > svg {
                    min-width: 25px;
                    min-height: 15px;
                    margin-top: 4px;
                }

                & > svg:hover ~ .text, .text:hover {
                    color: $brand-primary;
                    text-decoration: underline;
                    font-weight: 600;
                }
            }
        }
    }

    @media (max-width: $MOBILE-BREAKPOINT) {
        &__documents {
            width: unset;
        }
    }
}

.core-measures-help-container {
  margin-bottom: 2.5rem;

  a {
    text-decoration: none;
    color: $brand-secondary;

    &:hover {
      text-decoration: underline;
      font-weight: 600;
    }
  }

  .core-measures-help-breadcrumbs {
    margin-top: -20px;
    margin-bottom: 15px;
    font-size: .85em;

    .core-measures-help-breadcrumbs-divider {
      margin: auto 2px auto 2px;
    }
  }

  .core-measures-help-admin {
      margin-bottom: 15px;
  }

  .core-measures-help-folders-container {
    width: 100%;

    .core-measures-help-folder, .core-measures-help-file {
      width: 33%;
      margin-right: 2px;
      margin-top: 2px;
      margin-bottom: 2px;

      .core-measures-help-folder-name, .core-measures-help-file-name {
        width: 100%;

        span a {
            white-space: nowrap;

            span {
                white-space: normal;
                overflow-wrap: anywhere;
            }
        }
      }
    }
  }

  .core-measures-help-dropzone {
    position: relative;
    width: 100%;
    border: 3px dotted #000;
    background-color: #ccc;
    height: 75px;
    margin-top: 20px;

    span {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;
      text-align: center;
      padding: 25px;
    }

    input[type=file] {
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .core-measures-help-dropzone-hover {
    border-style: solid;
    background-color: rgba(25, 135, 84, .8);
  }
}
