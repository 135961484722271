.assessment-form-complete-container {
  padding-top: 1rem;
}

.assessment-form-page {
  height: 100%;

  .sv_progress {
    top: 0 !important;
  }

  main {
    height: 100%;
  }
}

