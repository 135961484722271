.external-link {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.--disabled {
        pointer-events: none;
        cursor: default;
    }

    &.--phone {
        &::before {
            display: inline;
            content: '';
            background-image: url('/icons/phone-icon.svg');
            background-size: 14px 14px;
            height: 14px;
            width: 14px;
            margin: 2px 0.5rem 0 0;
        }
    }
}