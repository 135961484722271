.print-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;

  .footer-name-wrapper {
    position: relative;

    .name-container {
      margin-left: -41px;
    }
  }
}
