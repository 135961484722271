@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.non-patient-search-content {
    form {
        width: $MAIN-CONTENT-WIDTH;

        & > hr {
            margin: 0 0 1.5rem 0;
        }
    }

    .facility-user-account-table, .rdt_Table {
        margin: 2rem 0 0 0;

        .rdt_TableRow,
        .rdt_TableHeadRow {
            & > div:nth-child(2) {
                min-width: 280px;
            }
        }

        .access-type-label {
            white-space: nowrap;
        }
    }

    .button-container {
        &__search, &__add-account {
            align-self: flex-end;

            & > button {
                width: 160px;
                height: 48px;
                padding: 0;

                &:first-child:not(:only-child) {
                    margin: 0 2rem 0 0;
                }
            }
        }

        &__search {
            margin: 0 0 1rem 0;
        }

        &__add-account {
            margin: 4rem 0;
        }
    }

        .dropdown-input {
        width: 100%;
        margin: 0 0 1.5rem 0;

        & > label {
            width: calc(50% - 0.75rem);

            &:not(:last-child) {
                padding: 0 1rem 0 0;
            }
        }
    }

    &__text-inputs, &__dropdown-inputs {
        width: 100%;
        margin: 0 0 1.5rem 0;
    }

    &__text-inputs {
        & > label {
            display: flex;
            flex-direction: column;

            &:not(:last-child) {
                width: 25%;
                padding: 0 1rem 0 0;
            }

            &:last-child {
                width: 50%;
            }
        }
    }

    &__dropdown-inputs {
        &__labels {
            width: 100%;

            & > label {
                width: calc(25% - 0.5rem);
                display: flex;
                flex-direction: column;

                &:not(:last-child) {
                    padding: 0 1rem 0 0;
                }
            }
        }
    }

    @media (max-width: $MOBILE-BREAKPOINT) {
        form, .facility-user-account-table, .rdt_Table {
            width: 100%;
        }
    }
}
