@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.react-dual-listbox {
  overflow: auto;
  width: 100%;
}

.rdl-filter-container {
  padding: 2px;
}

.rdl-controls {
  height: 100%;
}

.tablet-info {
  font-size: 0.85rem;
  color: $brand-primary;
  display: none;

  @media (max-width: $MOBILE-BREAKPOINT) {
    display: block;
  }
}

.dual-listbox {
  height: 100%;
}
