@import '../variables/colors.scss';
@import '../variables/text.scss';

// PrimaryHeader
h1 {
  &.text {
    font-size: 2rem;
  }
}

// SecondaryHeader
h2 {
  &.text {
    font-size: 1.5rem;

    @media print {
        font-size: 1.25rem;
    }
  }
}

// TertiaryHeader
h3 {
  &.text {
    font-size: 1rem;
  }
}

.--align-left {
  text-align: left;
}

// All Headers
h1, h2, h3 {
  &.text {
    font-weight: 600;
    margin-block: 0;
    margin-inline: 0;
  }
}

// Paragraph && InlineText
span, p {
  &.text {
    font-weight: 300;
    margin: 0;

    &.--extra-small {
      font-size: 0.625rem;
    }

    &.--small {
      font-size: 0.750rem;
    }

    &.--regular {
      font-size: 0.875rem;
    }

    &.--large {
      font-size: 1rem;
    }

    &.--extra-large {
      font-size: 1.125rem;
    }

    &.--super-extra-large {
      font-size: 1.75rem;
    }
  }
}

// All text
h1, h2, h3, p, span {
  &.text {
    font-family: $font-family;

    &.--primary {
      color: $brand-primary;
    }

    &.--secondary {
      color: $brand-secondary;
    }

    &.--dark {
      color: $brand-dark;
    }

    &.--background {
      color: $brand-background;
    }

    &.--dark-gray {
      color: $brand-gray;
    }

    &.--brand-gray {
      color: $brand-gray;
    }

    &.--light-gray {
      color: $brand-light-gray;
    }

    &.--low-priority {
      color: $brand-low-priority;
    }

    &.--medium-priority {
      color: $brand-medium-priority;
    }

    &.--high-priority {
      color: $brand-high-priority;
    }

    &.--white {
      color: white;
    }

    &.--bold {
      font-weight: 600;
    }

    &.--underlined {
      text-decoration: underline;
    }

    &.--italic {
      font-style: italic;
    }
  }
}

.hidden-test-text {
  display: none;
}
