@import 'global_elements/variables/colors.scss';

.popup-window {
    z-index: 20;
    position: fixed;
    width: auto;
    height: auto;
    border: solid 1px $brand-dark;
    background-color: $brand-background;
    padding: 2rem;
    box-shadow: 0 0 20px 5px $brand-gray;
}
