@import './global_elements/variables/colors.scss';
@import './global_elements/variables/text.scss';

body {
    margin: 0;
    padding: 0;
    font-family: $font-family;
    background-color: $brand-negative-space;

    @media print {
      background-color: white;
    }
}

.rdt_TableCol:hover > div {
    font-weight: 600;
    opacity: 1;
}

.rdt_TableCol:focus-within {
    background-color: $brand-primary;
    color: $brand-background;

    .rdt_TableCol_Sortable {
        opacity: 1;
        font-weight: 600;
    }
}

body .main-wrapper.--padded main > div.status-bar-bottom {
  position: fixed;
  bottom: 0;
  right: 0;

  background-color: transparent; // lightgrey;
  padding: 0.25rem;

  & > div {
    padding: 0.25rem 0.25rem 0.5rem 0.25rem;
  }

  .scroll-to-top {
    border-radius: 20px;
    background: #7d86ff; // $brand-secondary;
  }

  button {
    padding: 0.5rem;
    pointer-events: auto;
  }

  @media print {
    display: none;
  }
}

.card-primary {
  width: 100%;
  margin: 0 0 0.5rem 0;
  background-color: $brand-background;

  > * {
    width: 100%;
  }

  &__header {
    width: 100%;
  }

  .card-content {
    width: 100%;
  }
}

.card-primary-ref-wrapper {
  width: 100%;

  > .flex-col, > .flex-row {
    width: 100%
  }
}

.print-only {
  display: none;

  @media print {
    display: block;
    width: 100%;
  }
}

.no-print {
  @media print {
    display: none !important;
  }
}

.screen-invisible {
  visibility: hidden;
  position: absolute;
  top: -5000%;
  height: 0;
  width: 100%;

  @media print {
    visibility: visible;
    height: auto;
    position: unset;
  }
}

@page {
  // size: A4;
  // margin: 5% 2% 12% 2%;
  // margin-top: 7%;

  // @bottom-left-corner {
  //   content: "Page " counter(page);
  // }
}

.hidden, .flex-col.hidden, .flex-row.hidden {
  display: none;
}

.loading-icon-overlay-container {
  position: relative;
  height: 100%;

  > .loading-icon-overlay:not(.hidden) {
    position: absolute;
    font-size: 3rem;
    top: 50%;
    left: 50%;

    + * {
      opacity: 0.4;
      filter: alpha(opacity=40); /* msie */
    }
  }
}

.control-bar-top {
  margin-bottom: 1rem;
  width: 100%;
}
