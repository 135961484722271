@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.nav-link {
    cursor: pointer;

    &.--main {
        background-color: $brand-primary;
        width: $NAV-MAIN-ITEM-WIDTH;
        height: $NAV-MAIN-ITEM-HEIGHT;
        padding: $NAV-MAIN-TEXT-PADDING-TOP 0 0 $NAV-MAIN-TEXT-PADDING-LEFT;
    }

    &.--sub {
        background-color: $brand-primary;
        width: $NAV-MAIN-ITEM-WIDTH;
        height: $NAV-MAIN-ITEM-HEIGHT;
        padding: $NAV-MAIN-TEXT-PADDING-TOP 0 0 calc(#{$NAV-MAIN-TEXT-PADDING-LEFT} * 2);
    }

    &.--decorative {
        padding: 0;
        margin: 0;
    }

    &.--main, &.--decorative, &.--invisible, &.--sub {
        text-decoration: none;
    }

    &.--main, &.--decorative, &.--sub {
        &.--active {
            background-color: $brand-secondary;
            
            &:focus {
                background-color: $brand-medium-priority;
            }
        }
    }

    &:focus {
        outline: none;
        background-color: $brand-low-priority;
    }
}
