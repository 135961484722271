@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.non-patient-registration-content {
    & > form {
        width: 80%;

        & > .text {
            margin: 0 0 0.5rem 0;
        }

        & > hr {
            margin: 0 0 1.5rem 0;
        }

        & > label:not(.checkbox-tree__checkbox) {
            display: flex;
            flex-direction: column;
            margin: 0 0 1.5rem 0;
        }

        & > div:not(.popup-window-wrapper) {
            width: 100%;
            margin: 0 0 1.5rem 0;

            & > label:not(.checkbox-tree__checkbox) {
                display: flex;
                flex-direction: column;
                width: calc(50% - 1.5rem);

                &:nth-child(odd) {
                    margin: 0 3rem 0 0;
                }

                &:nth-child(2n+3) {
                    margin: 1.5rem 0 0 0;
                }
            }
        }

        .button-container {
            margin: 4rem 0;
            gap: 1.5rem;
            width: 100%;

            & > button {
                width: 125px;
                height: 48px;
                padding: 0 16px;
            }
        }
    }

    .filter-controls {
      margin-bottom: 1rem;

      & > div {
        width: 50%;
      }
      .filter-controls-right {
        padding-left: 3.5rem;
      }
    }

        .dropdown-input {
        width: 100%;
        margin: 0 0 1.5rem 0;

        & > label {
            width: calc(50% - 0.75rem);

            &:not(:last-child) {
                padding: 0 1rem 0 0;
            }
        }
    }

    .listbox-flex-container {
      height: 26em;
    }

    @media (max-width: $MOBILE-BREAKPOINT) {
        & > form {
            width: 100%;
        }
    }
}
