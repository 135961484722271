$brand-primary: #023860;
$brand-secondary: #337EAD;
$brand-gray: #8a8a8a;
$brand-dark: #2d2d2d;
$brand-light-gray: #595959;
$brand-background: #ffffff;
$brand-negative-space: #e1e4e9;
$brand-high-priority: #cc0000;
$brand-high-priority-dark: #8f0000;
$brand-medium-priority: #0f43d1;
$brand-low-priority: #34ad08;
$brand-light-text: #ffffff;

// validated MHO color scheme + $brand-dark
$brand-green: #84BD00;
$brand-dark-green: #638E00;
$brand-light-green: #CEE599;
$brand-light-teal: #A6CFC9;
$brand-light-blue: #6A9EBE;
$brand-sky-blue: #D2E2EC;
$brand-status: #F3F6F8;

// Non-MHO color scheme for special occasions.
$warning-color: #f37022; // OSHA orange
$danger-color: #ce1d2f;

// MHO Custom colors (Customer's pref from Jessica Ingram)
$main-percentile-color: #AAA6A6;
$secondary-percentile-color: #E6E6E6;
$program-score-color: #A1CBC5;
$compare-score-color: #8B8C90;
$patient-score-color: #367EAD;
