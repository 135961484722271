@import 'global_elements/variables/shared_dimensions.scss';

.register-by-email-content {
    &__inputs {
        width: 100vw;
        margin: 5rem 0 0 0;

        & > * {
            margin: 1rem 0;

            &:first-child {
                margin: 0 0 4rem 0;
            }
        }

        label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 25%;

            & > *:nth-child(2) {
                margin: 0 0 0.5rem 0;
            }
        }

        & > button {
            padding: 0.75rem 4rem;
        }
    }
}