@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.checkbox-tree {
    border: 1px solid $brand-gray;
    min-height: 400px;
    max-height: 400px;
    overflow: auto;

    &::-webkit-scrollbar {
        background-color: $brand-negative-space;
        width: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $brand-secondary;
        box-shadow: 0 0 1px $brand-gray;
        -webkit-box-shadow: 0 0 1px $brand-gray;
    }

    &__checkbox {
        display: flex;
        padding: 0.3rem 0 0.3rem 0;
        cursor: pointer;

        & > input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked ~ .checkbox-tree__custom-box {
                background-color: $brand-primary;

                &::after {
                    display: block;
                }
            }
        }

        &:hover {
            background-color: $brand-negative-space;

            .checkbox-tree__custom-box {
                background-color: $brand-secondary;
            }
        }
    }

    &__custom-box {
        cursor: pointer;
        background-color: white;
        width: 18px;
        height: 18px;
        border: 2px solid $brand-gray;
        margin: 0 0.5rem 0 0;

        &::after {
            content: "";
            position: relative;
            display: none;
            left: 5px;
            top: 1px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(40deg);
        }
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        & > .checkbox-tree__item {
            margin: 0 0 0 2rem;
        }

        & > .checkbox-tree__item__static {
            margin: 0 0 0 4rem;
        }
    }

    &__section, &__section__static {
        width: 100%;
        margin: 0.5rem 0 0 0;

        & > hr {
            width: 95%;
            margin: 0.5rem 0 0 1rem;
            border: 1px solid $brand-negative-space;
        }
    }

    &__section__static {
        & > .text {
            margin: 0 0 0 2rem;
        }
    }
}