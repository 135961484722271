@import 'global_elements/variables/colors.scss';

.delete-confirmation-popup-window-wrapper .popup-window {
  width: 900px !important;
  left: unset;
}

.popup-window-content {
  .warning-icon {
    color: $brand-high-priority;
    margin-right: 0.25rem;
  }

  .send-assessment-link {
    width: 100%;
  }

  .popup-window-buttons {
    margin-top: 1rem;

    button:not(:last-child) {
      margin-right: 10px;
    }
  }

  .popup-window-content {
    padding: 1rem 0 1rem 0;
  }
}
