.hide {
  display: none;
}

.flex-row {
    display: flex;
    flex-direction: row;
  }
  
.flex-col {
  display: flex;
  flex-direction: column;
}
  
.flex-col, .flex-row {
  &.--equal-size-items > * {
    flex: 1 0 auto;
  }

  &.--position {
    &-relative {
      position: relative;
    }

    &-absolute {
      position: absolute;
    }
  }

  &.--justify {
    &-start {
      justify-content: flex-start;
    }

    &-center {
      justify-content: center;
    }

    &-end {
      justify-content: flex-end;
    }
    
    &-space-evenly {
      justify-content: space-evenly;
    }

    &-space-around {
      justify-content: space-around;
    }

    &-space-between {
      justify-content: space-between;
    }
  }

  &.--align {
    &-start {
      align-items: flex-start;
    }

    &-center {
      align-items: center;
    }

    &-end {
      align-items: flex-end;
    }
  }

  &.--wrap {
    flex-wrap: wrap;
  }
}