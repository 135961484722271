@import 'global_elements/variables/colors.scss';

.labeled-text-input {
    &__header {
        margin: 0 0 0.5rem 0;
    }
}

.input-text-area {
    border: none;
    outline: none;
    background: none;
    cursor: text;
    pointer-events: auto;
    padding: 0.75rem;
    width: calc(100% - 1.5rem);
    font-family: Arial;

    &:focus {
        outline: $brand-primary solid 5px;
    }

    &.--primary {
        border: solid 1px $brand-gray;
        background-color: white;
    }

    &.--error {
        border: solid 2px $brand-high-priority;
        background-color: white;
    }

    &.--readonly {
        border: none;
        background-color: none;
        padding: 0;
        pointer-events: none;
        color: $brand-secondary;
    }
}
