@import 'global_elements/variables/shared_dimensions.scss';
@import 'global_elements/variables/colors.scss';

.admin-dashboard-content {
    .popup-window {
        width: 500px;
        top: calc(10vh);
        left: calc(#{$NAV-BAR-WIDTH} + (#{$MAIN-CONTENT-WIDTH} / 2) + #{$MAIN-SIDE-MARGIN} - 250px);

        @media (max-width: $MOBILE-BREAKPOINT) {
          max-width: 55%;
        }

        form {
            width: 100%;

            & > div {
                margin: 1.5rem 0 0 0;
            }

            .datetime-picker-custom-input {
                width: 75%;
                height: 38px;
                border: solid 1px $brand-gray;
            }
        }

        &__first-row {
            label {
                width: 30%;

                & > .text {
                    color: $brand-secondary;
                }
            }
        }

        &__second-row {
            label {
                width: 50%;

                & > .text {
                    color: $brand-secondary;
                }
            }
        }

        &__third-row, &__fourth-row {
            label {
                width: 100%;

                & > .text, & > div > .text {
                    color: $brand-secondary;
                }
            }

            input {
                width: calc(100% - 26px);
            }
        }

        &__facility-picker {
            label {
                width: 100%;

                & > .text {
                    color: $brand-secondary;
                }
            }
        }

        .button-container {
            .btn {
                width: 100px;

                &:first-child {
                    margin: 0 2rem 0 0;
                }
            }
        }
    }

    // Use a generic table and card css.  TODO move these outside of admin-dashboard-content so it's more accessible.
    .table-widget {
        width: 100%;
        margin: 0 0 3rem 0;

        .btn:hover > .text {
            font-weight: 600;
        }
    }

    .system-alerts-widget {
        .btn:hover > .text {
            text-decoration: underline;
        }
    }

    & > *:last-child {
        margin: 0 0 4rem 0;

        @media print {
          margin: 0;
        }
    }

    .printable-assessments-page-content {
      width: 100%;
    }
}
