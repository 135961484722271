@import url(https://unpkg.com/survey-core@1.8.70/survey.min.css);
@import 'global_elements/variables/shared_dimensions.scss';

.assessment-definition-search-page {
    input {
      margin-top: 10px;
      margin-bottom: 20px;
      width: 200px;
    }
}

.search-assessment-error-container {
    span {
        margin-bottom: 20px;
    }
}

.creator-element {
  height: calc(100vh - $HEADER-HEIGHT);
}
