@import 'global_elements/variables/shared_dimensions.scss';

.patient-account-setup-content {
    form {
        width: $MAIN-CONTENT-WIDTH;

        @media (max-width: $MOBILE-BREAKPOINT) {
          width: 100%;
        }

        label > .text {
            margin: 0 0 0.5rem 0;
        }
    }

    .button-container {
        align-self: flex-end;
        margin: 1rem 0 4rem 0;

        & > button {
            width: 160px;
            height: 48px;
            padding: 0;

            &:first-child {
                margin: 0 2rem 0 0;
            }
        }
    }

    &__first-row, &__second-row, &__third-row {
        width: 100%;
        margin: 0 0 1.5rem 0;

        & > label {
            display: flex;
            flex-direction: column;
            width: 40%;

            &:not(:last-child) {
                padding: 0 2rem 0 0;
            }
        }
    }

}
