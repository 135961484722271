.group-therapy-form-container {
  width: 500px;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  > *:not(button) {
    width: 100%;
  }

  textarea {
    height: 100px;
  }

  button {
    width: 160px;
  }

  #comments-label {
    display: none;
  }
}
