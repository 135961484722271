@import 'global_elements/variables/colors.scss';

.group-therapy-exit-session-container {
  padding: 3rem;
  background-color: $brand-background;
  margin: 25px 0 50px 0;
  width: 608px;

  h1 {
    margin: 0 0 1rem 0 ;
  }

  p.error-message {
    margin: 0 0 1rem 0;
  }

  .button-container {
    margin: 4rem 0 0 0;
    gap: 2rem;
  }
}