@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.assessment-verify-page {
  padding: 3rem;
  background-color: $brand-background;
  margin: 25px 0 50px 0;

  .assessment-verify-page__button-container button {
    margin-left: 20px;
    margin-top: 32px;
  }
}

.assessment-verify-page__button-container {
  width: 100%;
}

.datetime-picker-custom-input {
  width: 250px;
  height: 38px;
  border: solid 1px $brand-gray;

  &.--error {
    width: 250px;
    height: 38px;
    border: solid 2px $brand-high-priority;
  }
}
