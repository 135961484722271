.group-therapy-new-session {
  margin-bottom: 64px;
}

.group-therapy-resume-sessions {
  width: 100%;

  .group-therapy-session-work-list {
    width: 100%;

    .rdt_TableRow,
    .rdt_TableHeadRow {
      div:nth-child(1) {
        min-width: 140px;
      }
      div:nth-child(2) {
        min-width: 40%;
      }
      div:nth-child(3) {
        min-width: 20%;
      }
      div:nth-child(4) {
        max-width: 50px;
      }
    }
  }
}