@import 'global_elements/variables/colors.scss';

.fa-calendar {
  color: white;
}

.datepicker-button {
  height: 41.5px;
  margin-left: 0.5rem;
  padding: 0.75rem !important;
}

.labeled-date-picker-input__header {
  margin: 0 0 0.5rem 0;
}
