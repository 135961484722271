@import 'global_elements/variables/colors.scss';

 /* Dropdown Button */
 .dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 6px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  max-width: 30rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  max-height: 20rem;
  overflow: auto;
  padding-left: 20px;
}

/* Links inside the dropdown */
.dropdown-content li {
  color: black;
  padding: 8px 30px 8px 5px;
  text-decoration: none;
  display: block;
  white-space: nowrap;

  & > button.btn {
    font-size: 1rem;
    padding: 0;

    &:focus {
      outline: inherit;
    }
  }
}

/* Change color of dropdown links on hover */
.dropdown-content li:hover {background-color: #ddd}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}
