.group-therapy-work-list-container {
  width: 100%;
}

.provider-work-list {
  width: 100%;

  .rdt_TableRow,
  .rdt_TableHeadRow {
    & > div:nth-child(2) {
      min-width: 230px;
    }
    & > div:nth-child(6) {
      min-width: 230px;
    }
  }
}
