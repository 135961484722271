@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.patient-search-content {
    form {
        width: $MAIN-CONTENT-WIDTH;

        & > label > * {
            width: 50%;
        }


        & > label > *:last-child, & > p {
            margin: 0 0 2rem 0;
        }
    }

    .patient-account-table, .rdt_Table {
        .rdt_TableRow,
        .rdt_TableHeadRow {
            & > div:nth-child(1) {
                min-width: 200px;
            }
            & > div:nth-child(2) {
                min-width: 150px;
            }
            & > div:nth-child(3) {
                min-width: 150px;
            }
            & > div:nth-child(5) {
                min-width: 220px;
            }
        }
    }

    .button-container {
        &__search, &__add-account {
            align-self: flex-end;

            & > button {
                width: 160px;
                height: 48px;
                padding: 0;

                &:first-child:not(:only-child) {
                    margin: 0 2rem 0 0;
                }
            }
        }

        &__search {
            margin: 0 0 1rem 0;
        }

        &__add-account {
            margin: 4rem 0;
        }
    }

    &__text-inputs {
        width: 100%;
        margin: 0 0 1.5rem 0;

        & > label {
            width: 25%;

            &:not(:last-child) {
                padding: 0 1rem 0 0;
            }
        }
    }

    @media (max-width: $MOBILE-BREAKPOINT) {
        form, .patient-account-table, .rdt_Table {
            width: 100%;
        }
    }
}
