@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

.core-measures-facility-info-content {
  form {
    width: $MAIN-CONTENT-WIDTH;

    & > label > * {
      width: 50%;
    }


    & > label > *:last-child, & > p {
      margin: 0 0 2rem 0;
    }
  }

  &__text-inputs {
    width: 100%;
    margin: 0 0 1.5rem 0;

    & > label {
      width: calc(25% - 0.75rem);

      &:not(:last-child) {
        padding: 0 1rem 0 0;
      }
    }
  }

  &__list-inputs {
    width: 100%;
    margin: 0 0 1.5rem 0;

    & > label {
      width: 25%;

      &:not(:last-child) {
        padding: 0 1rem 0 0;
      }
    }
  }

  &_date-range-inputs {
    width: 100%;
    margin: 0 0 1.5rem 0;

    & > label {
      width: calc(25% - 0.75rem);

      &:not(:last-child) {
        padding: 0 1rem 0 0;
      }
    }
  }
}
