@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';

header {
    z-index: 10;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: $HEADER-HEIGHT;
    background-color: $brand-primary;

    @media print {
        display: none;
    }
}

.branding {
    width: $NAV-BAR-WIDTH;

    & > *:first-child {
        display: none;
    }

    @media (max-width: $MOBILE-BREAKPOINT) {
        & > *:first-child {
            display: block;
            margin-right: 10px;
        }
    }
}

.notification-detail-wrapper {
  .notification-nav-button {
    display: block;
    margin: 0;
    padding: 0;
  }
}

.notification-detail {
  position: absolute;
  top: 10rem;
  left: 50rem;


  .text {
    margin-bottom: 0.75rem;

    &.--extra-large {
      margin-bottom: 0;
    }
  }

  .footer {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  .close-button {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }

  .underlined {
    margin-top: 1rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.header-right-wrapper {
    width: $HEADER-RIGHT-WIDTH;
    height: $HEADER-HEIGHT;
    margin: 0 $HEADER-RIGHT-SIDE-MARGIN;

    @media (max-width: $MOBILE-BREAKPOINT) {
      width: calc(100vw - 200px - (2.5rem * 2));
      margin: 0 1rem;

      & > span {
        width: 250px;
        font-size: 1rem !important;
      }
    }

    .single-select-dropdown-wrapper {
        margin: 0 1rem 0 0;
    }

    .btn {
        width: 64px;
        height: 75px;
    }

    .account-button-container {
        .popup-window {
            right: 100px;
            padding: 0;
        }
    }

    .notifications-button-container {
        position: relative;

        .message-counter {
            position: absolute;
            right: 0.75rem;
            top: 2.25rem;
            width: 20px;
            height: 20px;
            background-color: $brand-secondary;
            border-radius: 10px;
        }

        .popup-window {
            right: 40px;
            width: 30%;
            height: 80%;
            min-width: 225px;
            max-width: 275px;
            min-height: 350px;
            max-height: 550px;
            padding: 1rem 1.5rem;
            overflow-y: auto;

            > div {
              width: 100%;
            }

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                width: 15px;
                background-color: $brand-background;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background-color: $brand-gray;
            }

            .notifications-menu {
                .close-button {
                    position: absolute;
                    right: 0.75rem;
                    top: 0.75rem;

                    .btn {
                        padding: 0;

                        &:hover {
                            background-color: $brand-background;
                        }
                    }
                }
            }
        }
    }

    .account-button-container, .notifications-button-container {
        .popup-window {
            position: fixed;
            top: 10px;
            box-shadow: 0 10px 20px 4px $brand-gray;

            .header-menu {
                background-color: $brand-background;

                .btn {
                    width: 100%;
                    height: 100%;
                    padding: 2rem 3rem;

                    &:hover {
                        background-color: $brand-secondary;

                        & > .text {
                            color: $brand-background;
                        }
                    }
                }

                hr {
                    width: 100%;
                    margin: 1rem 0;
                    border: solid 1px $brand-negative-space;
                }

                .notification {
                    width: 100%;

                    &:nth-of-type(2) {
                        margin: 1rem 0 0 0;
                    }

                    &__clickable {
                        width: 100%;

                        .btn {
                            padding: 0;
                            text-align: left;
                        }

                        .new {
                            width: 8px;
                            height: 8px;
                            border-radius: 8px;
                            background-color: $brand-secondary;
                        }

                        &:hover {
                            .btn {
                                background-color: $brand-background;
                            }

                            .text {
                                background-color: $brand-background;
                                color: $brand-primary;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}
