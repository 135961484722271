@import 'global_elements/variables/shared_dimensions.scss';

.mhr-card-group {
    width: $MAIN-CONTENT-WIDTH;

    &__resources {
        width: 100%;
    }

    @media (max-width: $MOBILE-BREAKPOINT) {
        width: unset;

        &__resources {
            margin-left: -1rem;
            margin-right: -1rem;
        }

        &__resources > div {
            padding: 0 1rem;
            width: calc(50% - 2rem);
        }
    }

    @media (max-width: 768px) {
        &__resources  > div {
            width: calc(100% - 2rem)
        }
    }
}
