@import 'global_elements/variables/colors.scss';
@import 'global_elements/variables/shared_dimensions.scss';
@import 'global_elements/variables/text.scss';

.assessment-results {
  @media print {
    margin-top: 0.625in;
    * { overflow: visible !important; }
}
}

.react-tabs {
    width: 100%;
    -webkit-tap-highlight-color: transparent;

    &__tab-list {
        border-bottom: 1px solid $brand-gray;
        margin: 0 0 10px 0;
        padding: 0;
        width: $MAIN-CONTENT-WIDTH;

        @media print {
          display: none;
        }
    }

    &__tab {
        background-color: white;
        display: inline-block;
        position: relative;
        list-style: none;
        padding: 6px 20px;
        cursor: pointer;
        transition: background-color 0.15s linear;

        &:hover {
            background-color: $brand-negative-space;
        }

        &--selected {
            background-color: white;
            border-bottom: 5px solid $brand-secondary;
            color: $brand-dark;

            &:hover {
                background-color: white;
            }

            &:focus {
                outline: none;
            }
        }

        &--disabled {
            color: $brand-gray;
            cursor: default;
        }
    }

    &__tab-panel {
        display: none;

        &--selected {
            display: block;
        }
    }
}

.control-bar-top {
  label.labeled-single-select {
    width: 35%;
    min-width: 100px;
  }
}

h1, h2 {
  @media print {
    width: calc(100% - 300px); // Adjust 300px for the sticker
  }
}

h1.text {
  @media print {
    font-size: 1.5rem;
  }
}

.overall-assessment-score {
  h3 {
    display: none;
  }

  @media print {
    h3 {
      display: block;
    }
  }
}

.icon-emoticon {
  font-size: 2rem;
  padding: 3px;
  margin-top: 5px;

  width: 25px;
  height: 25px;
}

.sd-rating__item--selected, .sv_q_rating_item.active {
  .icon-emoticon {
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
  }
}

.table-loading-spinner {
  font-size: 1.5rem;

  span {
    padding-left: 5px;
  }
}

.card-container--standalone {
    margin: 0 0 2.5rem 0;

    .card-primary {
        margin-bottom: 0;
    }
}

.btn--print {
    padding-left: 50px;
    padding-right: 50px;
    color: white;
    margin-top: auto;

    @media print {
      display: none;
    }
}

.chart-container {
  position: relative;
  height: 425px;
  order: 0;

  @media print {
      width: 1000px;
      height: 255px;
      margin-bottom: 0;
      padding-bottom: 0;
  }
}

.break-inside-avoid {
  break-inside: avoid;
}


.table-widget {
  margin-bottom: 80px;
  box-decoration-break: clone;

  &.extreme-response-handler {
    .rdt_TableCell {
      & > div {
        padding: 5px;
      }
    }

    .extreme-response-warning-text {
      color: $warning-color;
      font-size: 1rem;
      margin: .5rem 0;

      @media print {
        font-size: 1rem;
      }
    }

    .rdt_TableRow {
      break-inside: avoid;
    }

    @media not print {
      .extreme-response-cell {
        background-color: $warning-color;
        color: white;
      }
    }
  }

  .icon-control {
    padding-right: 5px;

    &.warning-icon {
      color: $warning-color;
    }
  }
}

@media print {
  .card-container--standalone.score-container {
    display: none !important;
  }

  .table-wrapper {
    orphans: 3;

    .rdt_Table {
      display: table !important;

      .rdt_TableHead {
        display: table-header-group !important;
        orphans: 0;
      }

      .rdt_TableBody {
        display: table-row-group !important;
        orphans: 3;
      }

      .rdt_TableHeadRow,
      .rdt_TableRow {
        display: table-row !important;

        > div {
          display: table-cell !important;
          vertical-align: middle;
          max-width: 450px;
        }
      }
    }
  }

  .table-widget {
    order: 1;

    .flex-row {
      display: inline-block !important;
    }
  }

  .super-admin-dashboard-content {
    margin-top: -15px;
    padding-bottom: 0;
    display: grid;

    &.flex-col {
      display: grid;
    }

    .card-primary-ref-wrapper {

      &.flex-col {
        display: grid;
      }
    }
  }

  .flex-col:not(.no-print, .hidden) {
    display: block !important;
  }
}

.main-wrapper.--padded main .survey-container {
  padding: 0;

  .sv-question,
  .sv-btn,
  .sv_main {
    font-family: $font-family;
  }

  // Survey Creator DefaultV2 color override
  // --primary-color: #8a7ff0;
  // --primary: #8a7ff0;
  // --secondary-color: #8a7ff0;
  // --primary-text-color: #8a7ff0;
  // --secondary-text-color: #a7a7a7;
  // --inverted-text-color: #ffffff;
  // --primary-hover-color: #7ff07f;

  // --selection-border-color: #1ab394;
  // --primary-icon-color: #3d4d5d;
  // --primary-bg-color: #f8f8f8;
  // --secondary-bg-color: #f4f4f4;
  // --primary-border-color: #e7eaec;
  // --secondary-border-color: #8a7ff0;
  // --error-color: #ed5565;
}

// For the popup/floating progress bar
// -----
.sv_main .sv_custom_header {
    display: none;
}

.sv_main .sv_container .sv_header {
  background-color: $brand-secondary;
}

.sv_header__text {
  display: flex !important;
  flex-flow: column nowrap;
  justify-content: space-between;
  gap: 1em;
}

.sv_main .sv_container .sv_progress {
  z-index: 1;
  background-color: #e0e0e0 !important;
  margin-left: 25px;
  margin-right: 25px;
  text-align: center;

  div.sv_progress_bar {
    background-color: $brand-green;
    min-width: 1%;
  }

  &.is-pinned {
    border-top: 1px solid black;
    box-shadow: 5px 5px 10px 1px;
    height: unset;

    div.sv_progress_bar {
      height: 0.5rem;
      margin-top: 8px;
    }

    span {
      background-color: $brand-status;
      display: block;
      text-indent: 5px;
    }
  }
}

.daisy-chain-card {
  p {
    margin-bottom: 1rem;
  }
}
