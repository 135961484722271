@import 'global_elements/variables/colors.scss';

.search-bar {
    & > input {
        height: 22px;
        width: 300px;
    }

    &__icon {
        height: 48px;
        width: 48px;
        padding: 0;
        background-color: $brand-secondary;
    }
}